import { useContext, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import useApiAxios from "../../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import PasswordResetForm from "./PasswordResetForm";


const ForgetPassword = () => {
  const { changePasswordOTP, forgetPasswordApi } = useApiAxios();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [otpSent, setOtpSent] = useState(false); // State to track OTP sent status

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
    

        const response = await forgetPasswordApi({ email: values.email });
        if (response.error) {
          setMessage(response?.data?.message || "Invalid email address");
          console.warn("PPPPPPPPPPPPPPPPPP",response)
        } else {
          setEmail(values.email);
          setMessage(`OTP Sent Successfully to ${values.email}!`);
          setOtpSent(true);
        }

        // Show success message
      } catch (error) {
        // Handle errors

        // Show invalid email error
        setMessage(error.response?.data?.message || "Invalid email address");
      }
    },
  });



  return (
    <main className="d-flex align-items-center min-vh-100 py-3 py-md-0 login-bg">
      <div className="container">
        <div className="card login-card">
          <div className="row no-gutters">
            <div className="col-md-5">
              <img src="assets/images/login.png" alt="login" className="login-card-img" />
            </div>
            <div className="col-md-7">
              <div className="card-body">
                
                <div className="row">
                  <div className="col-8">
                    <Link to="/" className="login-back">
                      <img width={7} className="me-3" /> Back to
                      Login
                    </Link>
                  </div>
               
                <div className="login-container m-auto">
                  
                    <h3 className="fw-bold login-title">Forgot Password</h3>
                    {message && (
                      <div
                        className={`alert ${message.includes("Successfully")
                            ? "alert-success"
                            : "alert-danger"
                          }`}
                      >
                        {message}
                      </div>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className={`form-control ${formik.touched.email && formik.errors.email
                              ? "is-invalid"
                              : ""
                            }`}
                          id="email"
                          disabled={otpSent}
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <button
                        type="submit"
                        disabled={otpSent}
                        className="btn btn-block btn_login w-100"
                      >
                        Send OTP
                      </button>
                    </form>

                    {otpSent && (
                      <PasswordResetForm
                        email={email}
                        changePasswordOTP={changePasswordOTP}
                      />
                    )}
                  </div>
                </div>
                {/* <Link to="/forgetPassword" className="forgot-password-link">Forgot password?</Link> */}
                {/* <nav className="login-card-footer-nav">
                  <Link to="#!">Terms of use.</Link>
                  <Link to="#!">Privacy policy</Link>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
