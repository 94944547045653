import React, { useContext, useEffect, useState } from 'react';
import Footer from "../footer/Footer";
import { Context } from "../../context/Context.js";
import { Link } from 'react-router-dom';

const Profile = () => {
  const { userData } = useContext(Context);
  
  const [name, setName] = useState(userData.name || '');
  const [email, setEmail] = useState(userData.email || '');

  useEffect(() => {
    // Update state if userData changes
    setName(userData.name || '');
    setEmail(userData.email || '');
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Updated Name:', name);
    console.log('Updated Email:', email);
  };

  return (
    <div className="wrapper">
      <div className="main-panel">
        <div className="content">
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">My Profile</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <Link to="/">
                        <i className="flaticon-home" />
                      </Link>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="/">My Account</a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="/">My Profile</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-md-8 col-xl-9">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Private info</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputFirstName">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputFirstName"
                            placeholder="First name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail4">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Profile;
