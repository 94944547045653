import React, { useContext } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";

const useApiAxios = () => {
  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("admintoken");
  axios.defaults.headers.common["Content-Type"] = "application/json";

  // You can also set headers for specific request methods
  axios.defaults.headers.get["Accept"] = "application/json";

  const {
    userData,
    setUserData,
    isAuthorized,
    setIsAuthorized,
    setIsLoading,
    openInfoSnackbar,
    openErrorSnackbar,
   
  } = useContext(Context);

  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const LOGIN = "/";
  const HOME = "/";
  const LOGOUT = "/logout";
  const CREATE_SUBADMIN = "/create/subAdmin";
  const GET_ALL_SUBADMIN = "/getAll/SubAdmin";
  const DELETE_SUBADMIN = "/delete/subAdmin";
  const FORGET_PASSWORD = "/forget/password";
  const CHANGE_FORGET_PASSWORD = "/forget/password";
  const GET_ALL_CONTACT_LIST = "/getall/Contact";
  const GET_ALL_VENDOR = "/getall/Vendor";
  const DELETE_CONTACT = "/delete/Contact";
  const DELETE_VENDOR = "/delete/Vendor";
  const UPDATE_VENDOR = "/update/Vendor/";
  const UPDATE_SUBADMIN = "/update/subAdmin";
  const GET_ALL_PAYMENT = "/adminPaymentList";




  const SEARCH_API = "/getAll/SubAdmin";
  const GET_DASHBOARD_DATA = "/Admindashboard";
  const GET_MARKER_PRICE = "/priceList";
  const UPDATE_MARKER_PRICE = "/update/priceList";


  const CHECK_EMAIL = "/check/email"
  



  const navigate = useNavigate();

  const loginApi = async ({ email, password, loggedIn }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${LOGIN}`, {
        email,
        password,
        loggedIn,
        from: "admin",
      });
      if (res.data.data.admin.role === "admin") {
        setUserData(res.data.data.admin);
       
        localStorage.setItem("admintoken", res.data.data.token);
        setIsAuthorized(true);
        console.log("Login successful:", res.data.data.token);
        navigate("/");
      } else {
        openInfoSnackbar("Invalid profile ");
        
      }
    } catch (error)
    
    {
      console.warn(error)
      openErrorSnackbar(error.response?.data?.message || "Invalid email or password");
    
    } finally {
      setIsLoading(false);
    }
  };

  const homeApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${HOME}`);
    
      setUserData(res.data.data);
      setIsAuthorized(true);
      // navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      navigate("/");
    }
  };

  const logOutApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${LOGOUT}`);
      localStorage.removeItem("admintoken");
      setUserData({});
      setIsAuthorized(false);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      navigate("/");
    }
  };

  const forgetPasswordApi = async ({ email, loggedIn }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${FORGET_PASSWORD}`, { email });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data.data };
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const changePasswordOTP = async ({
    otp,
    password,
    passwordConfirm,
    email,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(`${ENDPOINT}${CHANGE_FORGET_PASSWORD}`, {
        otp,
        password,
        passwordConfirm,
        email,
      });
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      navigate("/");
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const createSubAdminApi = async (formData) => {
    // console.warn("aaaaaaa", formData);
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_SUBADMIN}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      navigate("/")
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getAllSubAdmin = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_ALL_SUBADMIN}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const deleteSubAdminApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_SUBADMIN}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getAllContactList = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_ALL_CONTACT_LIST}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const deleteContactApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_CONTACT}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const deleteVendorApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_VENDOR}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const updateVendorApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_VENDOR}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateSubAdminApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_SUBADMIN}?id=${id}`,
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getAllPaymentApi = async (page = 0, limit = 10) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_ALL_PAYMENT}`, {
        params: { page, limit }, // Pass page and limit as query parameters
      });
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  
  const searchAdminApi = async (searchQuery) => {
    try {
      // setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${SEARCH_API}?search=${searchQuery}`);
      // setIsLoading(false);
      console.log(res.data);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      navigate("/");
    }
  };

  const getDashboardDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_DASHBOARD_DATA}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getMarketPrice = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_MARKER_PRICE}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const updateMarketPrice = async (formData) => {
    // console.warn("aaaaaaa", formData);
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${UPDATE_MARKER_PRICE}`, formData);
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const checkEmailApi = async ({ email }) => {
    try {
      const res = await axios.post(`${ENDPOINT}${CHECK_EMAIL}`, { email });
      return { error: false, data: res.data.message }
    } catch (error) {
   
      return { error: true, data: error.response.data.message }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    homeApi,
    loginApi,
    logOutApi,
    createSubAdminApi,
    getAllSubAdmin,
    forgetPasswordApi,
    changePasswordOTP,
    getAllContactList,
    // getAllVendor,
    deleteContactApi,
    deleteVendorApi,
    updateVendorApi,
    updateSubAdminApi,
    getAllPaymentApi,
    deleteSubAdminApi,
    searchAdminApi,
    getDashboardDataApi,
    getMarketPrice,
    updateMarketPrice,
    checkEmailApi
  };
};

export default useApiAxios;
