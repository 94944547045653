import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import ReactPaginate from "react-paginate";
import "./ViewUser.css"; // Import the CSS file
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ViewUser = () => {
  const { getAllSubAdmin, updateSubAdminApi, deleteSubAdminApi, searchAdminApi } = useApiAxios();
  const [adminData, setAdminData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); 

  const getUsers = () => {
    getAllSubAdmin()
      .then((response) => {
        console.warn("all subadmin", response);
        setAdminData(response.data.data);
      })
      .catch((error) => {
        setAdminData([]);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const openEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedUser(null);
    getUsers();
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to first page when items per page changes
  };

  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchAdminApi(query);
      console.log("Searched data:", response.data.data);
      setAdminData(response.data.data);
      setCurrentPage(0);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = adminData.slice(indexOfFirstItem, indexOfLastItem);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required")
      .test(
        "no-leading-whitespace",
        "Name cannot start with a space",
        (value) => value && value[0] !== " "
      ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .test(
        "no-leading-whitespace",
        "Email cannot start with a space",
        (value) => value && value[0] !== " "
      ),
  });
  

  return (
    <>
      <div
        className={`wrapper ${isEditModalOpen ? "modal-open" : ""}`}
        style={
          isEditModalOpen ? { filter: "blur(5px)", pointerEvents: "none" } : {}
        }
      >
        <div className="main-panel">
          <div className="content">
            <div className="panel-header">
              <div className="page-inner py-5">
                <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                  <div>
                    <h2 className="pb-2 fw-bold">View Sub Admin</h2>
                    <ul className="breadcrumbs">
                      <li className="nav-home">
                        <Link to="/">
                          <i className="flaticon-home" />
                        </Link>
                      </li>
                      <li className="separator">
                        <i className="flaticon-right-arrow" />
                      </li>
                      <li className="nav-item">
                        <Link to="/">SubAdmin</Link>
                      </li>
                      <li className="separator">
                        <i className="flaticon-right-arrow" />
                      </li>
                      <li className="nav-item">
                        <a href="#">View Sub Admin</a>
                      </li>
                    </ul>
                  </div>
                  <div className="ml-md-auto py-2 py-md-0">
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div class="collapse" id="search-nav">
                            <form class="navbar-left navbar-form nav-search mr-md-3">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <button
                                    type="submit"
                                    class="btn btn-search pr-1"
                                    onClick={handleSearch}
                                  >
                                    <i class="fa fa-search search-icon"></i>
                                  </button>
                                </div>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Search ..."
                                  value={searchQuery}
                                  onChange={handleSearch}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">All SubAdmin</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive table-container">
                        <table
                          id="basic-datatables"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Organization</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((user) => (
                              <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                                <td>{user.organization}</td>
                                <td>
                                  <>
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-link btn-primary btn-lg"
                                      data-original-title="Edit Tag"
                                      onClick={() => openEditModal(user)}
                                    >
                                      <i className="fa fa-edit" />
                                    </button>
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-link btn-danger"
                                      data-original-title="Delete Tag"
                                      onClick={async () => {
                                        await deleteSubAdminApi(user._id);
                                        getUsers();
                                      }}
                                    >
                                      <i className="fa fa-times" />
                                    </button>
                                  </>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-between align-items-center pagination-container">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(
                              adminData.length / itemsPerPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                          <div className="form-group d-flex align-items-center items-per-page">
                            <label htmlFor="itemsPerPage" className="mr-2">
                              Items per page:
                            </label>
                            <select
                              id="itemsPerPage"
                              className="form-control"
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={10}>10</option>
                              <option value={15}>15</option>
                              <option value={20}>20</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer/> */}
        </div>
      </div>

      {isEditModalOpen && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          id="editUserModal"
          tabIndex="-1"
          aria-labelledby="editUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                border: "1px solid #ccc",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
              }}
            >
              <div className="modal-header">
                <h2 className="modal-title" id="editUserModalLabel">
                  Edit User
                </h2>
                <button
                  type="button"
                  className="close"
                  onClick={closeEditModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={{
                    name: selectedUser ? selectedUser.name : "",
                    email: selectedUser ? selectedUser.email : "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateSubAdminApi(selectedUser._id, values)
                      .then((response) => {
                        console.log("Edit successful:", response.data);
                        closeEditModal();
                      })
                      .catch((error) => {
                        console.error("Edit failed:", error);
                      })
                      .finally(() => setSubmitting(false));
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="editedName">Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="editedName"
                          name="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="editedEmail">Email</label>
                        <Field
                          type="email"
                          className="form-control"
                          id="editedEmail"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeEditModal}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Save changes
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUser;
