import React, { useEffect, useState } from 'react'
import useApiAxios from "../../api/useApiAxios";
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';

const ContactList = () => {
  const { getAllContactList, deleteContactApi } = useApiAxios();
  const [contactData, setContactData] = useState([]); // State to store the selected course ID
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [currentPage, setCurrentPage] = useState(0);

  

  const fun =  async () => {
    getAllContactList()
      .then((response) => {
        console.warn('ContactList', response.data.data);
        setContactData(response.data.data.contacts);
      })
      .catch((error) => {
        setContactData([]);
        // Handle error, e.g., show an error message
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fun();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to first page when items per page changes
  }
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = contactData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <div className="main-panel">
        <div className="content">
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">Dashboard</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <Link to="/">
                        <i className="flaticon-home" />
                      </Link>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <Link to="#">Dashboard</Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="ml-md-auto py-2 py-md-0">
                  <div className="row">
                    <div className="col-6">
                      <input
                        type="text"
                        name="dates"
                        className="form-control pull-right"
                      />
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="#"
                            className="btn btn-primary btn-border btn-round mr-2"
                          >
                            <span className="btn-label">
                              <i className="fa fa-file-excel" />
                            </span>
                            Export
                          </a>
                        </div>
                        <div className="col-6">
                          <a href="#" className="btn btn-primary btn-round">
                            <span className="btn-label">
                              <i className="fa fa-print" />
                            </span>
                            Print
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">ContactList</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="modal fade"
                      id="addRowModal"
                      tabIndex={-1}
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header no-bd">
                            <h5 className="modal-title">
                              <span className="fw-mediumbold">New</span>
                              <span className="fw-light">Row</span>
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p className="small">
                              Create a new row using this form, make sure you fill
                              them all
                            </p>
                            <form>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group form-group-default">
                                    <label>Name</label>
                                    <input
                                      id="addName"
                                      type="text"
                                      className="form-control"
                                      placeholder="fill name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 pr-0">
                                  <div className="form-group form-group-default">
                                    <label>Position</label>
                                    <input
                                      id="addPosition"
                                      type="text"
                                      className="form-control"
                                      placeholder="fill position"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group form-group-default">
                                    <label>Office</label>
                                    <input
                                      id="addOffice"
                                      type="text"
                                      className="form-control"
                                      placeholder="fill office"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer no-bd">
                            <button
                              type="button"
                              id="addRowButton"
                              className="btn btn-primary"
                            >
                              Add
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>                          
                            <th>Email</th>
                            <th>About</th>                         
                            <th style={{ width: "10%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map(contact => (
                            <tr key={contact._id}>
                              <td>{contact.name}</td>
                            
                              <td>{contact.email}</td>
                              <td>{contact.about}</td>
                              <td>
                                <div className="form-button-action">
                                  { /*  <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit Task"
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                */}
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-link btn-danger"
                                    data-original-title="Remove"
                                    onClick={async () => {
                                      await deleteContactApi(contact._id);
                                      await fun();
                                    }}
                                  >
                                    <i className="fa fa-times" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-between align-items-center pagination-container">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(
                              contactData.length / itemsPerPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                          <div className="form-group d-flex align-items-center items-per-page">
                            <label htmlFor="itemsPerPage" className="mr-2">
                              Items per page:
                            </label>
                            <select
                              id="itemsPerPage"
                              className="form-control"
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={5}>05</option>
                              <option value={10}>10</option>
                              <option value={15}>15</option>
                              <option value={20}>20</option>
                            </select>
                          </div>
                        </div>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}

export default ContactList