import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link } from 'react-router-dom';

const CreateUser = () => {
  const { createSubAdminApi, checkEmailApi } = useApiAxios();
  const navigate = useNavigate();
  
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    organization: "",
    about: "",
    from: "subadmin",
  };
  
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Name must contain only alphabetic characters')
      .required('Name is required')
      .max(25, "Name must be at most 25 characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .max(50, "Email must be at most 50 characters"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Phone number must contain only numeric characters')
      .required('Phone number is required')
      .max(15, "Phone number must be at most 15 characters"),
    organization: Yup.string()
      .required('Organization is required')
      .max(50, "Organization must be at most 50 characters"),
  });

  const checkEmailOnChange = async (e) => {
    formik.handleChange(e);
    formik.setFieldTouched('email', true, true); // Mark email field as touched
    const emailValue = e.target.value;
    
    if (emailValue) {
      setIsCheckingEmail(true);
      const { error, data } = await checkEmailApi({ email: emailValue });
      setIsCheckingEmail(false);
      
      if (error) {
        formik.setErrors({ email: "Email already exists" });
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    } else {
      setIsEmailValid(false); // Reset email validity if the input is empty
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await createSubAdminApi(values);
    setSubmitting(false);
    navigate('/'); // Redirect to home page
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleBeforeInput = (event) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(event.data)) {
      event.preventDefault();
    }
  };

  return (
    <div className="main-panel">
      <div className="content">
        <div className="panel-header">
          <div className="page-inner py-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="pb-2 fw-bold">Create SubAdmin</h2>
                <ul className="breadcrumbs">
                  <li className="nav-home">
                    <Link to="/">
                      <i className="flaticon-home" />
                    </Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <Link to="/manageUser/viewUser">SubAdmin</Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <a href="#">Create SubAdmin</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Add Sub Admin</div>
                </div>
                <div className="card-body">
                  <style>
                    {`
                      .resizable-textarea {
                        resize: none; /* Prevent manual resizing */
                        overflow: hidden;
                        min-height: 100px;
                      }
                    `}
                  </style>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="col-md-12 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className={`form-control ${formik.errors.name && formik.touched.name ? "is-invalid" : ""}`}
                          id="name"
                          name="name"
                          placeholder="Name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          maxLength={25}
                          onBeforeInput={handleBeforeInput}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <div className="invalid-feedback">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className={`form-control ${formik.errors.email && formik.touched.email ? "is-invalid" : ""}`}
                          id="email"
                          name="email"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={checkEmailOnChange} // Use the custom email check
                          maxLength={50}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                        {isCheckingEmail && <div className="form-text">Checking email...</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          className={`form-control ${formik.errors.phone && formik.touched.phone ? "is-invalid" : ""}`}
                          id="phone"
                          name="phone"
                          placeholder="Phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          maxLength={15}
                        />
                        {formik.errors.phone && formik.touched.phone && (
                          <div className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="organization">Organization</label>
                        <input
                          type="text"
                          className={`form-control ${formik.errors.organization && formik.touched.organization ? "is-invalid" : ""}`}
                          id="organization"
                          name="organization"
                          placeholder="Organization"
                          value={formik.values.organization}
                          onChange={formik.handleChange}
                          maxLength={50}
                        />
                        {formik.errors.organization && formik.touched.organization && (
                          <div className="invalid-feedback">
                            {formik.errors.organization}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="card-action">
                      <button className="btn btn-primary mr-2" type="submit" disabled={!isEmailValid}>
                        Save
                      </button>
                      <Link to="/manageUser/viewUser">
                        <button className="btn btn-danger" type="button">Cancel</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
